export default function scrollToNavbar() {
    const width = parseInt(window.innerWidth)
    const breakpoint = 690 // Mobile

    if (width === breakpoint) {
        window.scrollTo(0, 52.5)
    }

    if (width > breakpoint) {
        window.scrollTo(0, 105)
    }

    if (width < breakpoint) {
        window.scrollTo(0, 67)
    }
}
