const initState = {
    PageHasLoaded: {
        home: false,
        about: false,
        products: false,
        factory: false,
        history: false,
        contact: false,
    },
}

const appReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOADED_HOME':
            return { ...state, PageHasLoaded: { ...state.PageHasLoaded, home: true } }

        case 'LOADED_ABOUT':
            return { ...state, PageHasLoaded: { ...state.PageHasLoaded, about: true } }

        case 'LOADED_PRODUCTS':
            return { ...state, PageHasLoaded: { ...state.PageHasLoaded, products: true } }

        case 'LOADED_FACTORY':
            return { ...state, PageHasLoaded: { ...state.PageHasLoaded, factory: true } }

        case 'LOADED_HISTORY':
            return { ...state, PageHasLoaded: { ...state.PageHasLoaded, history: true } }

        case 'LOADED_CONTACT':
            return { ...state, PageHasLoaded: { ...state.PageHasLoaded, contact: true } }

        default:
            return state
    }
}

export default appReducer
