import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { loadedFactory } from '../../../store/actions/app'

import scrollToNavbar from '../../layout/Navbar/utils/scrollToNavbar'
import technicalDetails from './static/technical-details.json'

import OFVesselImage from '../../assets/img/factory/ocean-fresh-vessel.JPG'
import PProcessImage from '../../assets/img/factory/packing-process.JPG'

import AOS from 'aos'
import { Row, Col } from 'antd'
import Loading from '../../layout/Loading'

import './Factory.scss'

function Factory({ currentPageLoaded, loadedPage }) {
    const [isLoadingImages, setLoadingImages] = useState(true)
    const [loadingImagesCount, setLoadingImagesCount] = useState(2)

    useEffect(() => {
        AOS.init({ once: true })
        document.title = 'Ocean Fresh - Factory'
        if (!currentPageLoaded) {
            scrollToNavbar()
        }
    }, [currentPageLoaded])

    useEffect(() => {
        if (loadingImagesCount <= 0) {
            setLoadingImages(false)
            loadedPage()
        }
    }, [loadingImagesCount, loadedPage])

    const onImageLoadHandler = () => {
        return setLoadingImagesCount(loadingImagesCount - 1)
    }

    return (
        <>
            <Loading hidden={!isLoadingImages} />
            <div className="factory-container" hidden={isLoadingImages}>
                <div className="our-factory">
                    <img className="background-image" src={OFVesselImage} alt="our-factory-background" onLoad={onImageLoadHandler} />
                    <div className="header noselect">
                        <h1>Our factory</h1>
                    </div>
                    <div data-aos="fade-left" data-aos-duration="1000" className="technical-details">
                        <h1>Technical Details</h1>
                        {technicalDetails.map(({ property, value }, index) => (
                            <Row key={index}>
                                <Col xs={12}>
                                    <p>{property}</p>
                                </Col>
                                <Col xs={12}>
                                    <p>{value}</p>
                                </Col>
                            </Row>
                        ))}
                    </div>
                    <div className="factory-details">
                        <div className="data-wrapper" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                            <h1>Ocean Fresh Fish Processing Factory (MDV-012)</h1>
                            <p>
                                Our factory strategically anchored right next to the international airport in the inner lagoon of Hulhumale’. Being at
                                this position enable us to deliver the finished products to airport on time without any delay, which is the gateway to
                                international markets. As for the fisherman, it’s easy for them to discharge their catch without any hassle as it is
                                anchored in the inner lagoon. This lagoon is accessible to all vessels any change of weather.
                            </p>
                            <p>
                                The advantages of being a sea based factory are more than a land based factory. As all boats discharge the fish after
                                alongside to the factory. Which means less handling is done and it’s good for the fish and keeps it more fresh. After
                                the fish is graded, the fish will go directly into the factory for processing and packing.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="our-process">
                    <img className="background-image" src={PProcessImage} alt="our-process-background" onLoad={onImageLoadHandler} />
                    <div className="header">
                        <h1>Our process</h1>
                        <div className="process-details">
                            <div className="data-wrapper" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                                <h1>On-Board Handling</h1>
                                <p>
                                    Fishes caught lining are undergone immediate process like killing, bleeding, evisceration and de-gilling &
                                    followed by immediate chilling in ICE: SW slurry.
                                </p>
                                <br />
                                <p>Storage temperature -0-2 C</p>
                                <p>
                                    For fish held iced or refrigerated on board the vessel and delivered 24 or more hours after death: the core
                                    temperature should be 4.4 °C
                                </p>
                                <p>
                                    For fish held iced or refrigerated on board the vessel and delivered from 12 to less than 24 hours after death:
                                    the core temperature should be 10 °C or below.
                                </p>
                                <p>
                                    For fish held ice or refrigerated on board the vessel and delivered in less than 12 hours after death: the core
                                    temperature should demonstrate that appropriate chilling methods were used onboard the harvest vessel. (Presence
                                    of ice completely covering the fish and storage temperature near to 0 °C.)
                                </p>
                                <br />
                                <h1>Fish Purchase</h1>
                                <p>
                                    Fishes are purchased after satisfying boat hygiene inspection, gathering fishing information, and preservation
                                    status as per the documented procedures and are graded, weighed and tagged individually.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentPageLoaded: state.app.PageHasLoaded.factory,
})

const mapDispatchToProps = (dispatch) => ({
    loadedPage: () => dispatch(loadedFactory()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Factory)
