import React, { Component } from 'react'
import { connect } from 'react-redux'
import scrollToNavbar from '../../layout/Navbar/utils/scrollToNavbar'
import products from './static/products.json'
import { loadedProducts } from '../../../store/actions/app'

import Loading from '../../layout/Loading'
import { Row, Col, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import BackgroundImage from '../../assets/img/background.jpg'

import './Products.scss'

class Products extends Component {
    state = {
        currentProduct: products[0],
        currentIndex: 0,
        animating: false,
        isMobile: false,
        isLoadingImages: true,
        loadingImagesCount: products.length + 2,
    }

    componentDidMount() {
        document.title = 'Ocean Fresh - Products'

        if (!this.props.currentPageLoaded) {
            scrollToNavbar()
        }

        window.addEventListener('resize', this.resize)
        this.resize()

        const stopLoading = message.loading('This page is under maintenance')

        setTimeout(stopLoading, 5000)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    onImageLoadHandler = async () => {
        await this.setState((prevState) => ({ loadingImagesCount: prevState.loadingImagesCount - 1 }))

        if (this.state.loadingImagesCount <= 0) {
            this.setState({ isLoadingImages: false })
            this.props.loadedPage()
        }
    }

    resize = async () => {
        const { innerWidth: width } = window

        if (width <= 960) {
            await this.setState({ isMobile: true })
        } else {
            await this.setState({ isMobile: false })
        }
    }

    updateCurrentProduct = async (currentProduct, { prev, next, click }) => {
        if (this.state.animating) {
            return
        } else {
            await this.setState({ animating: true })

            const animationSpeed = 500

            const info = document.querySelector('#current-product-info')
            const image = document.querySelector('#current-product-image')

            const fadeInUp = 'fade-in-up'
            const fadeInLeft = 'fade-in-left'
            const fadeInRight = 'fade-in-right'

            const addAnimation = (animation) => {
                if (!this.state.isMobile) {
                    info.classList.add(animation)
                }
                image.classList.add(animation)
            }

            const removeAnimation = (animation) => {
                if (!this.state.isMobile) {
                    info.classList.remove(animation)
                }
                image.classList.remove(animation)
            }

            removeAnimation(fadeInUp)
            removeAnimation(fadeInLeft)
            removeAnimation(fadeInRight)

            if (click) {
                addAnimation(fadeInUp)

                await this.setState({ currentProduct }, () => setTimeout(() => removeAnimation(fadeInUp), animationSpeed))
            }

            if (next) {
                addAnimation(fadeInRight)

                await this.setState({ currentProduct }, () => setTimeout(() => removeAnimation(fadeInRight), animationSpeed))
            }

            if (prev) {
                addAnimation(fadeInLeft)

                await this.setState({ currentProduct }, () => setTimeout(() => removeAnimation(fadeInLeft), animationSpeed))
            }

            await this.setState({ animating: false })
        }
    }

    clickProduct = async (e, id) => {
        e.preventDefault()

        if (this.state.animating || this.state.currentProduct.id === id) {
            return
        }

        let currentIndex = 0

        for (let product of products) {
            if (product.id === id) {
                break
            }

            currentIndex += 1
        }

        await this.setState({ currentIndex })

        const currentProduct = products[currentIndex]

        this.updateCurrentProduct(currentProduct, { click: true })
    }

    prevProduct = async () => {
        if (this.state.animating) {
            return
        }

        const { currentIndex } = this.state
        let _currentIndex = currentIndex

        if (currentIndex - 1 < 0) {
            _currentIndex = products.length - 1
            await this.setState({ currentIndex: _currentIndex })
        } else {
            _currentIndex -= 1
            await this.setState({ currentIndex: _currentIndex })
        }

        const currentProduct = products[_currentIndex]

        this.updateCurrentProduct(currentProduct, { prev: true })
    }

    nextProduct = async () => {
        if (this.state.animating) {
            return
        }

        const { currentIndex } = this.state
        let _currentIndex = currentIndex

        if (currentIndex + 1 > products.length - 1) {
            _currentIndex = 0

            await this.setState({ currentIndex: _currentIndex })
        } else {
            _currentIndex += 1

            await this.setState({ currentIndex: _currentIndex })
        }

        const currentProduct = products[_currentIndex]

        this.updateCurrentProduct(currentProduct, { next: true })
    }

    render() {
        const { currentProduct, isMobile, isLoadingImages } = this.state

        return (
            <>
                <Loading hidden={!isLoadingImages} />
                <div className="background-container" hidden={isLoadingImages}>
                    <img className="background-image" src={BackgroundImage} alt="background" onLoad={this.onImageLoadHandler} />
                    <div className="header">
                        <h1>Our products</h1>
                    </div>
                    <div className="products-container">
                        <div>
                            {isMobile ? ( // Mobile
                                <Row id="current-product">
                                    <Col xs={24} id="current-product-image" className="image">
                                        <FontAwesomeIcon icon={faChevronLeft} onClick={this.prevProduct} className="left-button" />
                                        <img src={currentProduct.url} alt={currentProduct.alt} onLoad={this.onImageLoadHandler} />
                                        <FontAwesomeIcon icon={faChevronRight} onClick={this.nextProduct} className="right-button" />
                                        <div id="current-product-image" className="title">
                                            <h1>{currentProduct.name}</h1>
                                        </div>
                                        <div id="current-product-image" className="description">
                                            <p>{currentProduct.description}</p>
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                // Desktop
                                <Row id="current-product">
                                    <Col xs={1} className="button" style={{ textAlign: 'left' }}>
                                        <FontAwesomeIcon icon={faChevronLeft} onClick={this.prevProduct} />
                                    </Col>
                                    <Col xs={11} id="current-product-info" className="info">
                                        <div className="info-wrapper">
                                            <h1>{currentProduct.name}</h1>
                                            <p>{currentProduct.description}</p>
                                        </div>
                                    </Col>
                                    <Col xs={11} id="current-product-image" className="image">
                                        <img src={currentProduct.url} alt={currentProduct.alt} onLoad={this.onImageLoadHandler} />
                                    </Col>
                                    <Col xs={1} className="button" style={{ textAlign: 'right' }}>
                                        <FontAwesomeIcon icon={faChevronRight} onClick={this.nextProduct} />
                                    </Col>
                                </Row>
                            )}
                            <div className="product-list">
                                <Row gutter={20} justify="center" style={{ margin: 0 }}>
                                    {products.map(({ id, name, alt, url }, index) => {
                                        const className = `product-list-item ${currentProduct.id === id ? 'active' : ''}`

                                        return (
                                            <Col key={index} lg={6} xs={8}>
                                                <div className={className} onClick={(e) => this.clickProduct(e, id)}>
                                                    <div className="image">
                                                        <img src={url} alt={alt} onLoad={this.onImageLoadHandler} />
                                                    </div>
                                                    <div className="name">
                                                        <p>{name}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    currentPageLoaded: state.app.PageHasLoaded.products,
})

const mapDispatchToProps = (dispatch) => ({
    loadedPage: () => dispatch(loadedProducts()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Products)
