import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadedHome } from '../../../store/actions/app'
import scrollToNavbar from '../../layout/Navbar/utils/scrollToNavbar'

import Loading from '../../layout/Loading'
import BackgroundImage from '../../assets/img/background.jpg'
import YellowFinTunaImage from '../../assets/img/yellow-fin-tuna.png'
import { Button } from 'antd'

import './Home.scss'

function Home({ currentPageLoaded, loadedPage }) {
    const [isLoadingImages, setLoadingImages] = useState(true)
    const [loadingImagesCount, setLoadingImagesCount] = useState(2)

    useEffect(() => {
        document.title = 'Ocean Fresh - Home'
        if (!currentPageLoaded) {
            scrollToNavbar()
        }
    }, [currentPageLoaded])

    useEffect(() => {
        if (loadingImagesCount <= 0) {
            setLoadingImages(false)
            loadedPage()
        }
    }, [loadingImagesCount, loadedPage])

    const onImageLoadHandler = () => {
        return setLoadingImagesCount(loadingImagesCount - 1)
    }

    return (
        <>
            <Loading hidden={!isLoadingImages} />
            <div className="background-container" hidden={isLoadingImages}>
                <img className="background-image" src={BackgroundImage} alt="background" onLoad={onImageLoadHandler} />

                <div className="home-container">
                    <div className="intro-container">
                        <img src={YellowFinTunaImage} alt="yellow-fin-yellow" onLoad={onImageLoadHandler} />
                        <br />
                        <h1>Tuna from the Maldives</h1>
                        <p>Our Promise of Freshness in it's purest form</p>
                        <br />
                        <Link to="/about">
                            <Button className="about-btn">About Us</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentPageLoaded: state.app.PageHasLoaded.home,
})

const mapDispatchToProps = (dispatch) => ({
    loadedPage: () => dispatch(loadedHome()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
