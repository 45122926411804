import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import Logo from '../../assets/img/navbar-logo.png'

import './Navbar.scss'

const links = [
    { path: '/', name: 'Home' },
    { path: '/about', name: 'About' },
    // { path: '/products', name: 'Products' },
    { path: '/factory', name: 'Factory' },
    // { path: '/history', name: 'History' },
    { path: '/contact', name: 'Contact' },
]

function Navbar({ location }) {
    return (
        <div className="navbar">
            <div className="items-container">
                <div>
                    <img className="logo" src={Logo} alt="navbar-logo" />
                </div>
                <ul>
                    {links.map(({ path, name }, index) => (
                        <li key={index}>
                            <Link to={path} className={location.pathname === path ? 'active' : null}>
                                {name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default withRouter(Navbar)
