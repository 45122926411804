import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import scrollToNavbar from '../../layout/Navbar/utils/scrollToNavbar'
import { loadedAbout } from '../../../store/actions/app'

import { Row, Col } from 'antd'
import CEOImage from '../../assets/img/ceo-portrait.jpg'
import AOS from 'aos'

import './About.scss'

function About({ currentPageLoaded, loadedPage }) {
    useEffect(() => {
        document.title = 'Ocean Fresh - About'
        AOS.init({ once: true })
        if (!currentPageLoaded) {
            scrollToNavbar()
            loadedPage()
        }
    }, [currentPageLoaded, loadedPage])

    return (
        <>
            <div className="about-container">
                <div className="about-content">
                    <div className="title">
                        <h1>Our company</h1>
                    </div>
                    <div className="content" data-aos="fade-up" data-aos-duration="1000">
                        <p>
                            Ocean Fresh Private Limited is one of the leading pioneers in the fisheries industry of the Maldives which had its modest
                            beginnings in 2001. Ocean Fresh started off by exporting tuna to buyers from neighboring Sri Lanka. The company had one
                            “dhoani” (boat) and 12 staff members when it began its operation.
                        </p>
                        <p>
                            In the years that followed, the company has successfully operated a fleet of long-line vessels, hand-line vessels, cargo
                            vessels, fuel barges along with an ice plant, a fish processing factory and numerous other fisheries related activities.
                        </p>
                        <p>
                            The steady growth of our company has opened countless doors for the local population to find respectable employment
                            opportunities. This has helped many to improve their livelihood and contributed to the economic growth of the community
                            overall.
                        </p>
                        <p>Today we are recognized as one of the leading exporters of fish products in the Maldives.</p>
                    </div>
                    <hr className="divider" />
                    <div className="title-alt">
                        <h1>Our corporate mission</h1>
                    </div>
                    <hr className="divider" />
                    <div className="content" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-center">
                        <p>
                            As we strive to be one of the premier fisheries companies in the Maldives, Ocean Fresh will continue to grow under strict
                            discipline and sustainable investment standards.
                        </p>
                        <p>
                            Our team will work together towards a common purpose of delivering ever improving value and quality to our fish products,
                            overall organizational performance and complete satisfaction of our clients. We aim to be the supplier of the quality fish
                            products in the Maldives and also be the company that gives the highest price for the local fisherman for their catch and
                            fishing products.
                        </p>
                        <p>
                            Moreover, we are committed to be instrumental in strengthening the local fishing industry and engage as many Maldivians in
                            the business development of Maldives Fisheries Industry.
                        </p>
                    </div>
                    <hr className="divider" />
                    <div className="title-alt">
                        <h1>Message from our ceo</h1>
                    </div>
                    <hr className="divider" />
                    <div className="ceo-message" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-center">
                        <Row>
                            <Col xl={10} md={24} xs={24}>
                                <img src={CEOImage} alt="ceo-portrait" />
                            </Col>
                            <Col xl={14} md={24} xs={24} className="message">
                                <i>
                                    <p>
                                        "To embark on a journey of success, one needs tools of prepared, foresight and strategy. These lead to path of
                                        growth and high quality operation.
                                    </p>
                                    <p>
                                        We continue to experience strong demand and high value for the fishing products and services we have been
                                        giving for past years. Ocean Fresh has learned to adapt to the changes in the markets and resources which has
                                        made our company a strong contender in the Maldives. We remain alert to the ever changing global economic
                                        spectrum and recognize the long term recurring nature of the fishing industry. In response we continue to
                                        focus on precise investment discipline, operational excellence and pursuing all opportunities to enhance the
                                        underlying performance of our business.
                                    </p>
                                    <p>
                                        The success and Ocean Fresh Pvt. Ltd. began as the dream of one man extended to be transformed into the dream
                                        of many. We strive to accomplish our dreams and goals to bring greater accomplishment in the future for the
                                        people of Maldives."
                                    </p>
                                </i>
                                <p className="no-margin">Abbas Mohamed</p>
                                <p>Chairman</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentPageLoaded: state.app.PageHasLoaded.about,
})

const mapDispatchToProps = (dispatch) => ({
    loadedPage: () => dispatch(loadedAbout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(About)
