import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import scrollToNavbar from '../../layout/Navbar/utils/scrollToNavbar'
import './Contact.scss'
import { Row, Col, Form, Input, Button } from 'antd'
import { loadedContact, sendMessage } from '../../../store/actions/app'
import contacts from './static/contacts.json'
import Loading from '../../layout/Loading/index'

const apiKey = 'AIzaSyBqq3ujZgJBe7X_Y9JtF6xiPtxWa5tadR4'
const locationUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=Ocean+Fresh+Private+Limited&zoom=18`

function Contact({ currentPageLoaded, loadedPage, sendMessage }) {
    const [form] = Form.useForm()
    const [isFormEmpty, setFormEmpty] = useState(true)

    useEffect(() => {
        document.title = 'Ocean Fresh - Contact'
        if (!currentPageLoaded) {
            scrollToNavbar()
            loadedPage()
        }
    }, [currentPageLoaded, loadedPage])

    const onChangeForm = (changed, all) => {
        const { name, email, message } = all

        const isEmpty = (str) => str === null || str === undefined || str?.length <= 0

        setFormEmpty(isEmpty(name) || isEmpty(email) || isEmpty(message))
    }

    const sendForm = async () => {
        const data = { ...form.getFieldsValue() }

        const success = await sendMessage(data)

        if (success) {
            form.resetFields()
            setFormEmpty(true)
        }
    }

    const formLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
    }

    // For Images
    const [isLoadingImages, setLoadingImages] = useState(true)
    const [loadingImagesCount, setLoadingImagesCount] = useState(1)

    useEffect(() => {
        if (loadingImagesCount <= 0) {
            setLoadingImages(false)
            loadedPage()
        }
    }, [loadingImagesCount, loadedPage])

    const onImageLoadHandler = () => {
        return setLoadingImagesCount(loadingImagesCount - 1)
    }

    return (
        <>
            <Loading hidden={!isLoadingImages} />
            <div className="contact-container" style={{ display: isLoadingImages ? 'none' : 'block' }}>
                <div className="header noselect">
                    <h1>Contact Us</h1>
                </div>
                <div className="content">
                    {contacts.map(({ fullname, title, email, phone }, index) => (
                        <div className="contact" key={index}>
                            <h3>{fullname}</h3>
                            <h5>{title}</h5>
                            <a href={`mailto:${email}`}>{email}</a>
                        </div>
                    ))}
                </div>
                <Row className="content">
                    <Col md={12} xs={24}>
                        <h2>Contact Form</h2>
                        <div className="content-col">
                            <Form className="contact-form" size="large" form={form} onValuesChange={onChangeForm} {...formLayout}>
                                <Form.Item name="name" label="Full Name">
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Form.Item name="email" label="Email">
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Form.Item name="message" label="Message">
                                    <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} showCount maxLength={100} autoComplete="off" />
                                </Form.Item>
                                <Button type="primary" htmlType="submit" onClick={sendForm} disabled={isFormEmpty}>
                                    Send
                                </Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={12} xs={24}>
                        <h2>Our Location</h2>
                        <div className="content-col">
                            <div className="iframe-wrapper">
                                <iframe style={{ border: 0 }} title="Our Location" src={locationUrl} loading="lazy" onLoad={onImageLoadHandler} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentPageLoaded: state.app.PageHasLoaded.history,
})

const mapDispatchToProps = (dispatch) => ({
    loadedPage: () => dispatch(loadedContact()),
    sendMessage: (data) => dispatch(sendMessage(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
