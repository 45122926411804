import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import Home from './components/pages/Home'
import About from './components/pages/About'
import Products from './components/pages/Products'
import Factory from './components/pages/Factory'
// import History from './components/pages/History'
import Contact from './components/pages/Contact'

import Error404 from './components/pages/Error404'

const routes = [
    { path: '/', component: Home, exact: true },
    { path: '/about', component: About, exact: true },
    { path: '/products', component: Products, exact: true },
    { path: '/factory', component: Factory, exact: true },
    // { path: '/history', component: History, exact: true },
    { path: '/contact', component: Contact, exact: true },
    { path: '*', component: Error404, exact: true },
]

const renderRoute = (route, index) => {
    if (route.null) {
        return null
    } else if (route.redirect) {
        return <Redirect key={index} to={route.path} />
    } else if (route.exact) {
        return <Route key={index} exact path={route.path} component={route.component} />
    } else {
        return <Route key={index} path={route.path} component={route.component} />
    }
}

export { routes, renderRoute }
