import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { Provider } from 'react-redux'
import store from './store'

import 'antd/dist/antd.css'
import 'aos/dist/aos.css'
import './index.scss'

const AppWrapper = () => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    )
}

ReactDOM.render(<AppWrapper />, document.getElementById('root'))
