import React from 'react'

import './Loading.scss'

const LoadingIcon = () => (
    <div className="ripple-loading">
        <div className="inner">
            <div></div>
            <div></div>
        </div>
    </div>
)

function Loading({ hidden }) {
    return (
        <div className="loading-container" hidden={hidden || false}>
            <div className="loading-icon">
                <LoadingIcon />
            </div>
        </div>
    )
}

export default Loading
