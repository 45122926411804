import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import Infobar from './components/layout/Infobar'
import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'

import { routes, renderRoute } from './routes'

import { BackTop } from 'antd'
import './App.scss'

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <BackTop />
                <Infobar />
                <Navbar />
                <Switch>{routes.map((route, index) => renderRoute(route, index))}</Switch>
                <Footer />
            </div>
        </BrowserRouter>
    )
}

export default App
