import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import scrollToNavbar from '../../layout/Navbar/utils/scrollToNavbar'

import BackgroundImage from '../../assets/img/background.jpg'
import Loading from '../../layout/Loading'
import { Button } from 'antd'

import './Error404.scss'

function Error404({ currentPageLoaded, loadedPage }) {
    const [isLoadingImages, setLoadingImages] = useState(true)
    const [loadingImagesCount, setLoadingImagesCount] = useState(2)

    useEffect(() => {
        document.title = 'Ocean Fresh - Page Not Found'
        if (!currentPageLoaded) {
            scrollToNavbar()
        }
    }, [currentPageLoaded])

    useEffect(() => {
        if (loadingImagesCount <= 0) {
            setLoadingImages(false)
            loadedPage()
        }
    }, [loadingImagesCount, loadedPage])

    const onImageLoadHandler = () => {
        return setLoadingImagesCount(loadingImagesCount - 1)
    }

    return (
        <>
            <Loading hidden={!isLoadingImages} />
            <div className="error404-container" hidden={isLoadingImages}>
                <img className="background-image" src={BackgroundImage} alt="background" onLoad={onImageLoadHandler} />
                <div className="header">
                    <h1>Error 404</h1>
                </div>
                <div className="message-container">
                    <Link to="/">
                        <Button className="home-btn">Go back to homepage</Button>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Error404
