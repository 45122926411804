import { message as Message } from 'antd'

export const loadedHome = () => (dispatch) => dispatch({ type: 'LOADED_HOME' })
export const loadedAbout = () => (dispatch) => dispatch({ type: 'LOADED_ABOUT' })
export const loadedProducts = () => (dispatch) => dispatch({ type: 'LOADED_PRODUCTS' })
export const loadedFactory = () => (dispatch) => dispatch({ type: 'LOADED_FACTORY' })
export const loadedHistory = () => (dispatch) => dispatch({ type: 'LOADED_HISTORY' })
export const loadedContact = () => (dispatch) => dispatch({ type: 'LOADED_CONTACT' })

const isEmpty = (str) => !str || str?.length <= 0

export const sendMessage = (data) => async () => {
    const stopLoading = Message.loading('Sending message')

    const { name, email, message } = data

    if (isEmpty(name) || isEmpty(email) || isEmpty(message)) {
        stopLoading()
        Message.error('Please complete the form before sending')
        return false
    }

    if (message?.length <= 25) {
        stopLoading()
        Message.error('Your message must be more than 25 characters')
        return false
    }

    stopLoading()
    Message.success('Message sent!')
    return true
}
