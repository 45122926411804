import React from 'react'

import { Row } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import './Infobar.scss'

function Infobar() {
    return (
        <Row className="info-bar">
            <div className="text-container">
                <div className="address">
                    <a href="https://goo.gl/maps/4Cm9cKgDxpfuTHeY7" target="blank">
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        &nbsp;&nbsp;1A, Lot 10376, Hirundhu Magu, Hulhumalé, Maldives
                    </a>
                </div>
                <div className="contact">
                    <a href="mailto:info@oceanfresh.com.mv">info@oceanfresh.com.mv</a>
                </div>
            </div>
        </Row>
    )
}

export default Infobar
